import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var _excluded = ["href", "children"]; /* @jsx jsx */
import { useStaticQuery } from 'gatsby';
import { jsx, css } from '../../util/emotionReact';
var LinkTo = function LinkTo(_ref) { var href = _ref.href, children = _ref.children, props = _objectWithoutPropertiesLoose(_ref, _excluded); var data = useStaticQuery("3918656684"); var url = new URL(href, "http://relative_url"); var request = url.pathname.replace(/^\/+|\/+$/, ""); switch (url.protocol) {
    case "tech:":
    case "technology:": {
        var techNode = data.technologies.nodes.find(function (n) { return n.ref === request; });
        if (techNode) {
            return jsx("a", { href: techNode.url, title: techNode.tagline }, children || techNode.name);
        }
        if (process.env.NODE_ENV === "production") {
            throw new Error("linkto: Could not find technology with ref " + request);
        }
        return jsx("b", { css: css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n            background-color: red;\n          "]))) }, "TECHNOLOGY ", url.hostname, " NOT FOUND");
    }
    case "person:":
    case "partner:": {
        var personNode = data.people.nodes.find(function (n) { return n.ref === request; });
        if (personNode) {
            return jsx("a", { href: personNode.url }, children || personNode.name);
        }
        if (process.env.NODE_ENV === "production") {
            throw new Error("linkto: Could not find person with ref " + request);
        }
        return jsx("b", { css: css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n            background-color: red;\n          "]))) }, "PARTNER ", url.hostname, " NOT FOUND");
    }
    case "Organization:":
    case "job:":
    case "org:": {
        var OrganizationNode = data.organizations.nodes.find(function (n) { return n.ref === request; });
        if (OrganizationNode) {
            return jsx("a", { href: OrganizationNode.url, title: OrganizationNode.tagline }, children || OrganizationNode.name);
        }
        if (process.env.NODE_ENV === "production") {
            throw new Error("linkto: Could not find Organization with ref " + request);
        }
        return jsx("b", { css: css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n            background-color: red;\n          "]))) }, "COMPANY ", url.hostname, " NOT FOUND");
    }
    case "http:":
    case "https:":
    case "mailto:": return jsx("a", _extends({ href: href }, props), children);
    default:
        if (process.env.NODE_ENV === "production") {
            throw new Error("linkto: Unexpected protocol " + url.protocol + " for " + href);
        }
        return jsx("b", { css: css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n            background-color: red;\n          "]))) }, "UNEXPECTED PROTOCOL ", url.protocol, " for ", href);
} };
export default LinkTo;
